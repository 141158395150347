
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  IonIcon,
  IonFab,
  IonFabButton,
  IonSearchbar,
} from "@ionic/vue";
import { refresh, chevronForward } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import apiInterventi from "../services/interventi";
import { openToast } from "../services/toast";

import moment from "moment";

export default {
  name: "Tab2",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    IonIcon,
    IonFab,
    IonFabButton,
    IonSearchbar,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const interventi = ref([]);
    const allInterventi = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    const searchTerms = ref("");

    function filterInterventi(searchTerms) {
      if (searchTerms.length > 2) {
        //console.log(searchTerms);
        interventi.value = allInterventi.value.filter(
          (int) =>
            (int.customers_company ? int.customers_company : "")
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(searchTerms.replace(/\s/g, "").toLowerCase()) ||
            int.customers_name
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(searchTerms.replace(/\s/g, "").toLowerCase()) ||
            int.customers_last_name
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(searchTerms.replace(/\s/g, "").toLowerCase()) ||
            (int.customers_last_name + int.customers_name)
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(searchTerms.replace(/\s/g, "").toLowerCase()) ||
            (int.customers_name + int.customers_last_name)
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(searchTerms.replace(/\s/g, "").toLowerCase())
        );
      } else {
        interventi.value = allInterventi.value;
      }
      //list.filter((i) => i.label.toLowerCase().includes(text.toLowerCase()));
    }

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    // const riferimentoCliente = computed(() => {
    //   return (intervento) => {
    //     //console.log(intervento);
    //     if (
    //       (intervento.customers_name != null &&
    //         intervento.customers_last_name != null) ||
    //       (intervento.customers_name != "" &&
    //         intervento.customers_last_name != "")
    //     ) {
    //       return `${intervento.customers_name} ${intervento.customers_last_name}`;
    //     } else {
    //       return `${intervento.customers_company}`;
    //     }
    //   };
    // });

    function riferimentoCliente(intervento) {
      if (intervento.customers_company) {
        return `${intervento.customers_company}`;
      } else {
        return `${intervento.customers_name} ${intervento.customers_last_name}`;
      }
    }

    /**
     *
     * Return '-' if a customer field is null
     *
     */
    function checkField(field) {
      if (field == null) {
        return " ";
      } else {
        return " - " + field;
      }
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    /**
     *
     * Passing id to retrive detail in InterventoDetail page
     *
     */
    function openDetail(id: string) {
      router.push(`tab3/${id}`);
    }

    function getInterventi() {
      //console.log("getInterventi");
      loading.value = true;
      apiInterventi
        .getInterventi(userID)
        .then((response) => {
          interventi.value = response;
          allInterventi.value = response;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta degli interventi", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      //GET INTERVENTI DATA
      getInterventi();
    });

    return {
      loading,
      interventi,
      riferimentoCliente,
      dateFormat,
      refresh,
      openDetail,
      getInterventi,
      checkField,
      /* New field for figma layout */
      chevronForward,
      filterInterventi,
      searchTerms,
      allInterventi,
    };
  },
};
